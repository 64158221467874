import React, { Component } from "react"
import Link from "next/link"
import { generateObfuscatedLink } from "../helpers/SEO"
import { CDN_DOMAIN } from "../config/cdn"
import { LinkedInIcon } from "../icons"

class UECFooter extends Component {
    render() {
        return (
            <footer
                style={{
                    background: "#0db3ac",
                    marginTop: 60,
                    paddingTop: 40,
                    paddingBottom: 60
                }}
            >
                <div className="mr-md-custom2 row footer-logos">
                    <div
                        className="col-md-5"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <div>
                            <img
                                src={`${CDN_DOMAIN}/static/svg/logo-unemplacement-blanc.svg`}
                                alt="Unemplacement.com"
                                title="Unemplacement.com"
                                style={{
                                    marginTop: 20,
                                    width: 200,
                                    margin: "auto"
                                }}
                            />
                        </div>
                        <div
                            className="subtitle-logo"
                            data-nosnippet
                        />
                        <div className="social-network">
                            <a
                                href="https://www.linkedin.com/company/unemplacement-com"
                                target="_blank"
                                rel="nofollow noopener"
                            >
                                <LinkedInIcon
                                    width={35}
                                    height={35}
                                    color="white"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div
                            className="row"
                            style={{
                                fontFamily:
                                    "Lato, Roboto, Helvetica, Arial, sans-serif",
                                fontSize: 15,
                                color: "white"
                            }}
                        >
                            <div className="col-md-4 footer-menu">
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        marginBottom: 25
                                    }}
                                >
                                    A propos
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    {!this.props.obfuscateLinks &&
                                        <Link href="/qui-sommes-nous">
                                            <a className="white-link">
                                                Qui sommes-nous ?
                                            </a>
                                        </Link>
                                    }
                                    {this.props.obfuscateLinks &&
                                        generateObfuscatedLink("/qui-sommes-nous", "Qui sommes-nous ?")
                                    }
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    <a
                                        href="https://blog.unemplacement.com/petits-dejeuners/"
                                        className="white-link"
                                    >
                                        Evènements
                                    </a>
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    <a
                                        href="/guide"
                                        className="white-link"
                                    >
                                        Guide
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4 footer-menu">
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        marginBottom: 25
                                    }}
                                >
                                    Conditions
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    {!this.props.obfuscateLinks &&
                                        <Link href="/mentions-legales">
                                            <a className="white-link">
                                                Mentions légales
                                            </a>
                                        </Link>
                                    }
                                    {this.props.obfuscateLinks &&
                                        generateObfuscatedLink("/mentions-legales", "Mentions légales")
                                    }
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    {!this.props.obfuscateLinks &&
                                        <Link href="/protection-des-donnees">
                                            <a className="white-link">
                                                Protection des données
                                            </a>
                                        </Link>
                                    }
                                    {this.props.obfuscateLinks &&
                                        generateObfuscatedLink("/protection-des-donnees", "Protection des données")
                                    }
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    {!this.props.obfuscateLinks &&
                                        <Link href="/cgu">
                                            <a className="white-link">CGU</a>
                                        </Link>
                                    }
                                    {this.props.obfuscateLinks &&
                                        generateObfuscatedLink("/cgu", "CGU")
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 footer-menu">
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        marginBottom: 25
                                    }}
                                >
                                    Contactez-nous
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    Téléphone :{" "}
                                    <a
                                        href="tel:+33622497152"
                                        className="white-link"
                                    >
                                        06 22 49 71 52
                                    </a>
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    <a
                                        href="mailto:contact@unemplacement.com?subject=UnEmplacement.com demande d'information"
                                        className="white-link"
                                    >
                                        Envoyer un e-mail
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default UECFooter
